import styled from "@emotion/styled";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getRealestate } from "api/realestate.request";
import CustomCarrusel from "shared/components/custom-carrusel";
import { MessageResults } from "shared/components/messages-result";
import PropertyCard from "shared/components/property-card";
import PropertyCardCollectionSkeleton from "shared/components/skeletons/property-collector-card.skeleton";
import { TitleThickThin } from "shared/components/titles";
import React from "react";
import Link from "next/link";
import { CPagesPath } from "shared/models/const/pages-path";
import { useTranslations } from "next-intl";
import useGetPersistParams from "custom-hooks/useGetPersintParamas.hook";
import useWindowSize from 'custom-hooks/useWindowsSize.hook';


function PropertySliderHomeSection() {
  const translation: any = useTranslations("home.exclusiveProperties");
  const sliderTitle = translation("title") || "";
  const size = useWindowSize();
  const width: any = size?.width;

  const fetchProjectSlider = async (e: any) => {
    const response = await getRealestate({ page: 1, exclusives: true });
    return response;
  };
  const ref = useGetPersistParams();


  const { data, status, isLoading, isError, mutate } = useMutation(
    ["project-slider"],
    fetchProjectSlider
  );

  React.useEffect(() => {
    mutate({});
  }, []);

  if (data! && data?.data?.length > 0 && isLoading) {
    return <MessageResults message="Sin Resultados" />;
  }

  if (isError) {
    return <MessageResults message="Ha ocurrido un error" />;
  }

  const [title, subtitle] = sliderTitle.split(" ");

  return (
    <>
      <article className="properties_slider">
        <div className="container">
          <TitleThickThin thickTitle={title} thinTitle={subtitle} />

          <CustomCarrusel
            enablePlaceholder
            placeholderItem={<PropertyCardCollectionSkeleton />}
            numberOfPlaceholderItems={4}
            forcePlaceHolder={data && data?.data?.length == 0 && isLoading}
          >
            {data?.data?.map((property: any, i: number) => (
              <Link key={i} href={CPagesPath.es.property_detail(property) + ref} passHref>
                <CardWrapper key={i} target={width > 991 ? "_blank" : ""}  rel='nofollow noopener noreferrer'
                >
                  <PropertyCard {...property}></PropertyCard>
                </CardWrapper>
              </Link>
            ))}
          </CustomCarrusel>
        </div>
      </article>

      <style jsx>
        {`
          /***********        PROPIEDADES DESTACADAS     ***********/
          .properties_slider {
            width: 100%;
            padding: 50px 100px;
            margin-bottom: 50px;
          }
          .content_Card {
            display: flex;
            justify-content: center;
          }

          @media screen and (max-width: 991px) {
            .properties_slider {
              padding-left: 0;
              padding-right: 0;
            }
          }
          @media screen and (max-width: 501px) {
            .properties_slider {
              // background:red;
              // padding: 50px 100px;
            }
          }
        `}
      </style>
    </>
  );
}

export default PropertySliderHomeSection;

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
  margin-bottom: 50px;
`;

const CardWrapper = styled.a`
  display: flex;
  justify-content: center;
  margin: 5px;
`;
