import ArticlesBlogHomeSection from "shared/components-pages/home/articles-blog-home-section";
import CitiesHomeSection from "shared/components-pages/home/cities-home-section";
import CollectionHomeSection from "shared/components-pages/home/collection-home-section";
import Cover from "shared/components-pages/home/cover";
import FranchisesHomeSection from "shared/components-pages/home/franchises-home-section";
import LendingHomeSection from "shared/components-pages/home/lending-home-section";
import PropertySliderHomeSection from "shared/components-pages/home/property-slider-home-section";
import ProyectHomeSection from "shared/components-pages/home/proyect-home-section";
import BuySellFilter from "shared/components/buy-sell-filter";
import HeadMeta, { IHeadMetaComponent } from "shared/components/head-meta";
import { en, es } from "lang";
import { CPagesPath } from "shared/models/const/pages-path";
import { CKeyWordsPages } from "shared/models/const/keywords.const";

import type { GetStaticPropsContext, NextPage } from "next";
import Banners from "@components/news/baners";
import Layout from "@components/layout";
const Home: NextPage = (props) => {
  const metaData: IHeadMetaComponent = {
    keyWords: CKeyWordsPages.home,
    description: "home",
    url: "/",
    title: "home",
    picture: "/images/image-optimize/living_meta.jpg",
  };

  return (
    <Layout showFooter={true}>
      <HeadMeta {...metaData} />
      {/* <Banners /> */}
      <Cover>
        <BuySellFilter detailsRoute={CPagesPath.es.property_detail} />
      </Cover>

      <PropertySliderHomeSection />

      <CollectionHomeSection />

      <ProyectHomeSection />

      <FranchisesHomeSection />

      <ArticlesBlogHomeSection />

      <LendingHomeSection />

      <CitiesHomeSection />
    </Layout>
  );
};

export default Home;

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: locale === "es" ? es : en,
    },
  };
}
