import Typography from "shared/components/Typography";
import React from "react";
import SliderCollectionProperty from "shared/components/slider-collection-property";
import styled from "@emotion/styled";
import Link from "next/link";
import { CPagesPath } from "shared/models/const/pages-path";
import Button from "shared/components/Button";
import { useTranslations } from "next-intl";

function CollectionHomeSection() {
  const translation = useTranslations("home.collection");

  return (
    <>
      <article className="collection">
        <div className="container">
          <section className="colletion__header">
            <div className="collection__logo">
              <img
                src="images/image-optimize/remax-collection.webp"
                alt="Logo Remax Collection"
                className="collection__logo_image"
              />
            </div>
            <div className="collection__description">
              <Title
                color="--white"
                fontSize="--fs-title"
                fontWeight={500}
                className="collection__description__title m-l-10 p-b-20"
              >
                {translation("title")}
              </Title>
              <Typography
                className="m-v-20"
                fontSize="--fs-body-small"
                color="--white"
                fontWeight={400}
              >
                {translation("description")}
              </Typography>
              <Typography
                className="m-v-20"
                fontSize="--fs-body-small"
                color="--white"
                fontWeight={400}
              >
                {translation("team")}
              </Typography>
            </div>
          </section>
          <SliderCollectionProperty></SliderCollectionProperty>
          <CollectionButton>
            <Link
              href={CPagesPath.es.collection}
              passHref
            >
              <Button component="a"> {translation("seeMore")}</Button>
            </Link>
          </CollectionButton>
        </div>
      </article>
      <CollectionHomeSectionStyles></CollectionHomeSectionStyles>
    </>
  );
}

export default CollectionHomeSection;

const Title = styled(Typography)`
  white-space: nowrap;
  font-size: 1.65rem;
`;

const CollectionButton = styled.article`
  width: 100%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const CollectionHomeSectionStyles = () => {
  return (
    <style jsx={true}>
      {`
        /* COLLECTION */
        .collection {
          width: 100%;
          background-color: var(--color-blue-main-collector);
          border-radius: 150px 0 0 0;
          padding-top: 150px;
          padding-bottom: 100px;
        }
        .colletion__header {
          display: flex;
          margin-bottom: 70px;
        }
        .collection__logo {
          width: 40%;
          align-self: center;
          text-align: center;
        }
        .collection__logo_image {
          width: 50%;
        }
        .collection__description {
          width: 50%;
        }
        .collection__description__title {
          white-space: pre-line;
        }
        .collection__title {
          text-align: left !important;
          font-size: 2rem;
          font-weight: 500;
        }
        .collection__text {
          line-height: 20px;
          margin-bottom: 30px;
          font-size: 0.875rem;
        }
        .collection__title,
        .collection__text,
        .collection__thumbnail_label,
        .collection__card__description {
          color: #fff;
        }
        .collection__slider {
          text-align: center;
          margin-bottom: 100px;
        }
        /* FIN COLLECTION */

        @media screen and (max-width: 991px) {
          .collection {
            padding-bottom: 50px;
          }
          .colletion__header {
            flex-wrap: wrap;
          }
          .collection__logo_image {
            width: 100%;
            max-width: 200px;
          }
          .collection__logo {
            margin-bottom: 30px;
          }
          .collection__logo,
          .collection__description {
            width: 100%;
            max-width: 550px;
            margin-right: auto;
            margin-left: auto;
            padding: 0px 20px;
          }
          h2.collection__title {
            white-space: nowrap;
            font-size: 1.6rem;
            text-align: center;
          }
        }

        @media screen and (max-width: 767px) {
          .collection {
            border-radius: 0;
          }
        }
        @media screen and (max-width: 575px) {
          .collection__slider {
            margin-bottom: 50px;
          }
          .collection {
            padding-top: 120px;
          }
          .colletion__header {
            display: flex;
            margin-bottom: 50px;
          }

          .collection__title {
            text-align: center !important;
          }
        }
        @media screen and (max-width: 485px) {
          .collection {
            padding-top: 90px;
          }
          .collection__description__title {
            text-align: center;
          }
          .collection__title {
            text-align: center !important;
          }
        }
      `}
    </style>
  );
};
